<template>
  <div>
    <Select
        v-model="selectedLanguage"
        :options="languages"
        optionLabel="label"
        optionValue="value"
        @change="changeLanguage"
        class="w-12rem change-lang-container"
        overlayClass="p-select-overlay"
    >
      <!-- Отображение списка языков -->
      <template #option="{ option }">
        <div class="language-option">
          <span :class="['flag-icon', option.icon]" class="flag-icon"></span>
          {{ option.label }}
        </div>
      </template>

      <!-- Отображение выбранного языка (флаг + текст) -->
      <template #value="{ value }">
        <div v-if="value" class="selected-language">
          <span :class="['flag-icon', currentLanguage.icon]" class="flag-icon"></span>
          <span class="language-text">{{ currentLanguage.label }}</span>
        </div>
      </template>
    </Select>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import Select from "primevue/select";

export default {
  components: {
    Select,
  },
  setup() {
    const { locale } = useI18n();

    const languages = ref([
      { label: "English", value: "en", icon: "flag-icon-gb" },
      { label: "Русский", value: "ru", icon: "flag-icon-ru" },
    ]);

    const selectedLanguage = ref(locale.value);

    const changeLanguage = ({ value }) => {
      if (languages.value.some((lang) => lang.value === value)) {
        locale.value = value;
        localStorage.setItem("language", value);
      } else {
        console.error("Некорректный язык:", value);
      }
    };

    // Вычисляем текущий язык (флаг + название) через computed()
    const currentLanguage = computed(() => {
      return languages.value.find((lang) => lang.value === selectedLanguage.value) || languages.value[0];
    });

    return {
      selectedLanguage,
      languages,
      changeLanguage,
      currentLanguage,
    };
  },
};
</script>

<style lang="scss">
.language-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selected-language {
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 991px) {
    padding: 5px;
  }
}

.flag-icon {
  width: 1.2rem;
  height: 0.8rem;
  display: inline-block;
  background-size: cover;
}

.language-text {
  font-size: 1rem;
  font-weight: 500;
}

/* Флаги */
.flag-icon-gb {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/gb.svg");
}

.flag-icon-ru {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ru.svg");
}

.language-text {
  @media (max-width: 991px) {
    display: none;
  }
}

.p-select-overlay {
  background: #202124 !important;

  .p-select-option-selected {
    background: transparent !important;
  }

  .p-focus {
    background: var(--p-button-primary-background) !important;
  }

  .p-select-option {
    color: white !important;

    &:hover, &:focus {
      background: var(--p-button-primary-background) !important;
    }
  }
}

.change-lang-container {
  background: transparent !important;

  .language-text {
    color: white !important;
  }
}
</style>

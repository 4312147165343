<template>
  <div id="footer" class="footer">
    <div class="d-flex">
      <div class="left">
        <img class="logo" src="../assets/svg/logo.svg" alt="Logo">
      </div>
      <div class="right d-flex align-items-center">
        <a href="https://x.com/disigames">X</a> |
        <a href="https://t.me/disicoin">Telegram RU</a> |
        <a href="https://t.me/disicoin_eng">Telegram EN</a> |
        <a href="https://t.me/disi_forum">{{ $t('footer.forum') }}</a> |
        <div class="chatra" @click="openChat"> {{ $t('footer.chatSupport') }}</div>
      </div>
    </div>
    <hr>
    <div class="d-flex">
      <div class="left">
        <div class="company-info">
          {{ $t('footer.company') }} <br><br>
          {{ $t('footer.address') }}
        </div>
      </div>
      <div class="right d-flex">
        <div class="copyright">© 2025 Disi | {{ $t('footer.rights') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from 'primevue/button';
import { onMounted } from 'vue';
import { getCurrentInstance } from 'vue';


const { proxy } = getCurrentInstance();

const openChat = () => {
  console.log('open chat');
  proxy.$openChatra();
};

onMounted(() => {
  proxy.$initChatra();
});
</script>

<style lang="scss">
.logo {
  width: 80px;
  cursor: pointer;
  @media (max-width: 1280px) {
    width: 50%;
    max-width: 100px;
  }
}
.footer {
  padding: 25px;
  padding-top: 100px;
  max-width: 1360px;
  margin: auto;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    display: block;
  }
  .left {
    width: 50%;
    align-content: center;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .right {
    width: 50%;
    gap: 10px;
    font-size: 14px;
    text-align: right;
    align-content: center;
    flex-wrap: wrap;
    justify-content: right;
    display: flex;
    @media (max-width: 991px) {
      width: 100%;
      margin-top: 25px;
      display: block;
    }
  }
}
.chatra {
  cursor: pointer;
  color: rgba(255, 255, 255, .87);
  &:hover {
    color: white;
  }
}
</style>
import PrimeVue from 'primevue/config';
import Lara from '@primevue/themes/lara';

import Button from 'primevue/button';
import Chart from 'primevue/chart';
import Timeline from 'primevue/timeline';
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import Dialog from 'primevue/dialog';
import Drawer from 'primevue/drawer';
import InputText from 'primevue/inputtext';
import IftaLabel from 'primevue/iftalabel';
import ProgressBar from 'primevue/progressbar';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon'
import OverlayPanel from 'primevue/overlaypanel';
import Toast from 'primevue/toast'; // Импортируем Toast
import ToastService from 'primevue/toastservice';

// Import PrimeVue styles
import 'primeicons/primeicons.css';

// Кастомизация
import '@/assets/css/theme.scss'; // Импорт вашего кастомного файла стилей

export default function primevue(app) {
    app.use(PrimeVue, {
        ripple: true,
        pt: Lara,
        theme: {
            preset: Lara,
            options: {
                prefix: 'p',
                colorScheme: 'dark',
                arkModeSelector: '[data-theme="dark"]',
                cssLayer: false
            }
        }
    });
    app.use(ToastService);

    app.component('Button', Button);
    app.component('Toast', Toast); // Глобальная регистрация Toast
    app.component('Chart', Chart);
    app.component('Timeline', Timeline);
    app.component('Accordion', Accordion);
    app.component('AccordionPanel', AccordionPanel);
    app.component('AccordionHeader', AccordionHeader);
    app.component('AccordionContent', AccordionContent);
    app.component('Dialog', Dialog);
    app.component('Drawer', Drawer);
    app.component('InputText', InputText);
    app.component('IftaLabel', IftaLabel);
    app.component('ProgressBar', ProgressBar);
    app.component('InputGroup', InputGroup);
    app.component('InputGroupAddon', InputGroupAddon);
    app.component('OverlayPanel', OverlayPanel);
}
<template>
  <div class="payments">
    <!-- Добавлен прелоадер -->
    <header class="p-header">
      <i class="fa-duotone fa-regular fa-xmark-large close-modal" @click="closeModal" />
    </header>
    <div v-if="isLoading" class="payments-container">
      <div class="preloader"></div>
    </div>
    <div v-else class="payments-container">
      <div class="w-100">
        <h4 class="purple font-weight-bold">{{ $t('payment.stage1') }} {{stageInfo.phaseNumber}}: {{stageInfo.phaseName}}</h4>
        <div class="text-center">
          {{ $t('payment.currentPrice') }}: <span class="text-white">${{stageInfo.priceDic}}</span><br>
          <span v-if="stageInfo.nextPrice">{{ $t('payment.nextStagePrice') }}: <span class="text-white">${{stageInfo.nextPrice}}</span></span>
        </div>
        <ProgressBar :value="((stageInfo.recruited / stageInfo.purpose) * 100).toFixed(2)" />
        <div class="text-center">
          {{ $t('payment.raisedThisStage') }}: <span class="text-white font-weight-bold">{{(stageInfo.recruited * stageInfo.priceDic).toFixed(2)}} $</span>
        </div>

        <div class="network">
          <div class="network-container">
            <div
                v-for="net in networks"
                :key="net.id"
                class="network-option"
                :class="{
                  active: networkCode[this.walletStore.walletType] === net.id,
                  disabled: this.walletStore.walletAddress && networkCode[this.walletStore.walletType] !== net.id
                }"
                @click="selectNetwork(net.id)"
            >
              <img class="network-img" :src="net.icon"> {{ net.id }}
            </div>
          </div>
        </div>

        <div class="p-input-group">
          <div>{{ $t('payment.promoCode') }}</div>
          <InputGroup>
            <InputText
                v-model="promoCode"
                class="payments-input"
                :placeholder="$t('payment.promoCodePlaceholder')"
            />
            <InputGroupAddon>
              <Button icon="pi pi-check" @click="activatePromoCode" />
            </InputGroupAddon>
          </InputGroup>
        </div>

        <div class="change">
          <div class="p-input-group">
            <div>{{ $t('payment.paymentIn', { network: networkCode[this.walletStore.walletType] || 'TON' }) }}</div>
            <InputGroup>
              <InputText
                  v-model.number="inputAmount"
                  class="payments-input"
                  type="number"
                  :placeholder="$t('payment.amountPlaceholder')"
                  @input="updateDicAmountFromInput"
              />
              <InputGroupAddon>
                <img class="change-img" :src="networkIcons[networkCode[this.walletStore.walletType] || 'TON']">
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div class="p-input-group">
            <div>{{ $t('payment.receiveDIC') }}</div>
            <InputGroup>
              <InputText
                  v-model.number="dicAmount"
                  class="payments-input"
                  type="number"
                  :placeholder="$t('payment.amountPlaceholder')"
                  @input="updateInputAmountFromDic"
              />
              <InputGroupAddon>
                <img class="change-img" :src="dicIcon">
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
        <span
            v-if="dicAmount < 10"
            class="text-white"
        >
          {{ $t('payment.minAmount') }}
        </span>
        <Button class="transaction" :disabled="(dicAmount < 10) || (!stageInfo.private && !stageInfo.canBuy)" @click="handleTransaction">{{ getButtonText() }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Connection, PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import SHA512 from 'crypto-js/sha512';
import { api } from "@/plugins/api";
import { beginCell } from '@ton/core';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';

// Import images
import tonNetworkIcon from '@/assets/network/ton.png';
import solNetworkIcon from '@/assets/network/sol.png';
import tonTokenIcon from '@/assets/token/ton.png';
import solTokenIcon from '@/assets/token/sol.png';
import dicTokenIcon from '@/assets/token/dic.png';

import { useWalletStore } from "@/store/wallet";
import { useModalStore } from "@/store/modal";
import tonConnectUI from "@/plugins/tonconnect";

export default {
  data: () => ({
    isLoading: true,
    promoCode: '',
    inputAmount: 0,
    dicAmount: 10,
    selectedNetwork: 'ton',
    networkCode: {
      ton: 'TON',
      solana: 'SOL'
    },
    prices: {
      TON: 1,
      SOL: 1,
    },
    networks: [
      { id: 'TON', icon: tonNetworkIcon },
      { id: 'SOL', icon: solNetworkIcon },
    ],
    networkIcons: {
      TON: tonTokenIcon,
      SOL: solTokenIcon
    },
    dicIcon: dicTokenIcon,
    stageInfo: {
      stageNumber: 2,
      stageName: 'Presale',
      phaseNumber: 1,
      phaseName: 'Seed Phase',
      private: 0,
      priceDic: 0.034,
      purpose: 7000000,
      recruited: 0,
      nextPrice: 0.03876,
      canBuy: true
    },
    walletProvider: null,
    walletType: null,
    activeTransactionId: 0
  }),

  watch: {
    // Оставляем только наблюдение за внешними изменениями
    'walletStore.walletType'(newType) {
      if (newType === 'ton') {
        this.selectedNetwork = 'ton';
        this.walletType = 'ton';
      } else if (newType === 'solana') {
        this.selectedNetwork = 'solana';
        this.walletType = this.walletStore.walletAdapter === 'Phantom' ? 'phantom' : 'solflare';
      } else {
        this.selectedNetwork = 'ton';
        this.walletType = 'ton';
      }
    },
    prices: {
      handler() {
        this.updateInputAmount();
      },
      deep: true,
    },
    'stageInfo.priceDic'() {
      this.updateInputAmount();
    },
    'prices.TON'() {
      this.isLoading = false;
      this.updateInputAmount();
    },
  },

  async created() {
    this.isLoading = true;
    try {
      console.log('Before restoreConnection:', {
        walletType: this.walletStore.walletType,
        walletAddress: this.walletStore.walletAddress,
        walletProvider: this.walletStore.walletProvider,
        dicAmount: this.dicAmount,
        inputAmount: this.inputAmount,
      });

      await this.walletStore.restoreConnection();
      console.log('After restoreConnection:', {
        walletType: this.walletStore.walletType,
        walletAddress: this.walletStore.walletAddress,
        walletProvider: this.walletStore.walletProvider,
        dicAmount: this.dicAmount,
        inputAmount: this.inputAmount,
      });

      if (this.walletStore.walletType === 'ton') {
        this.selectedNetwork = 'ton';
        this.walletType = 'ton';
        if (typeof tonConnectUI !== 'undefined' && tonConnectUI?.wallet) {
          this.walletStore.setWallet(tonConnectUI.wallet.account.address, 'ton', 'TonConnect');
        } else {
          console.warn('Ton Connect wallet not connected, clearing wallet state...');
          this.walletStore.clearWallet();
        }
      } else if (this.walletStore.walletType === 'solana' && this.walletStore.walletProvider) {
        this.selectedNetwork = 'solana';
        this.walletType = this.walletStore.walletAdapter === 'Phantom' ? 'phantom' : 'solflare';
        this.walletProvider = this.walletStore.walletProvider;
        if (!this.walletProvider.isConnected) {
          console.log('Wallet not connected after restore, attempting to reconnect...');
          try {
            await this.walletProvider.connect({ onlyIfTrusted: true });
            if (!this.walletProvider.isConnected) {
              console.warn('Auto-reconnect failed, requesting manual connect...');
              await this.walletProvider.connect();
            }
          } catch (error) {
            console.error('Failed to reconnect Solana wallet:', error);
            this.walletStore.clearWallet();
            this.selectedNetwork = 'ton';
            this.walletType = 'ton';
          }
        }
      } else {
        console.log('No wallet type set, defaulting to TON...');
        this.selectedNetwork = 'ton';
        this.walletType = 'ton';
      }

      await Promise.all([this.getStageInfo(), this.getCoinCourses()]);
      console.log('After loading data:', {
        walletType: this.walletStore.walletType,
        walletAddress: this.walletStore.walletAddress,
        walletProvider: this.walletStore.walletProvider,
        prices: this.prices,
        stageInfo: this.stageInfo,
        dicAmount: this.dicAmount,
        inputAmount: this.inputAmount,
      });

      if (!this.dicAmount || this.dicAmount !== 10) {
        console.warn('dicAmount сброшен или не равен 10, восстанавливаем значение: 10');
        this.dicAmount = 10;
      }

      this.updateInputAmount();
    } catch (error) {
      console.error('Error in created:', error);
      if (!this.walletStore.walletAddress) {
        this.toast.add({
          severity: 'error',
          summary: this.$t('payment.error'),
          detail: this.$t('payment.restoreConnectionFailed'),
          life: 3000,
        });
      } else {
        this.toast.add({
          severity: 'warn',
          summary: 'Предупреждение',
          detail: 'Восстановление подключения не удалось, но кошелек уже авторизован: ' + error.message,
          life: 3000,
        });
      }
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    getButtonText() {
      if (!this.stageInfo.private && !this.stageInfo.canBuy) {
        return this.$t('payment.presaleFinished');
      } else {
        return this.$t('payment.buy');
      }
    },

    async getCoinCourses() {
      try {
        const res = await api.get('/getCoinCourses');
        if (res.data.success) {
          this.prices = res.data.courses || {TON: 1, SOL: 1};
          console.log('Курсы валют загружены:', this.prices);
        } else {
          console.error('Ошибка загрузки курсов:', res.data);
          this.prices = {TON: 1, SOL: 1};
        }
      } catch (error) {
        console.error('Ошибка при запросе курсов:', error);
        this.prices = {TON: 1, SOL: 1};
      }
    },

    async getStageInfo() {
      try {
        const response = await api.post('/getStageInfo', {
          address: this.walletStore.walletAddress ? this.walletStore.walletAddress : false,
        });
        if (response.data.success) {
          this.stageInfo = response.data.stage;
          console.log('Stage info loaded:', this.stageInfo);
        } else {
          console.error('Failed to load stage info:', response.data);
        }
      } catch (error) {
        console.error('Error fetching stage info:', error);
      }
    },

    async activatePromoCode() {
      try {
        if (!this.promoCode) {
          this.toast.add({
            severity: 'error',
            summary: this.$t('payment.promoCodeError'),
            detail: this.$t('payment.promoCodeEmpty'),
            life: 3000
          });
          return;
        }

        const response = await api.post('/activatePromoCode', {
          address: this.walletStore.walletAddress ? this.walletStore.walletAddress : false,
          promo: this.promoCode
        });

        if (response.data.success) {
          await this.getStageInfo();
          this.toast.add({
            severity: 'success',
            summary: this.$t('payment.promoCodeSuccess'),
            detail: this.$t('payment.promoCodeActivated'),
            life: 3000
          });
          this.promoCode = '';
        } else {
          this.toast.add({
            severity: 'error',
            summary: this.$t('payment.promoCodeError'),
            detail: response.data.message || this.$t('payment.promoCodeInvalid'),
            life: 3000
          });
        }
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: this.$t('payment.promoCodeError'),
          detail: error.message || this.$t('payment.promoCodeFailed'),
          life: 3000
        });
      }
    },

    async confirmTransaction(params) {
      await api.post('/confirmedTransaction', params)
          .then(async res => {
            if (res.data.success) {
              this.activeTransactionId = 0;
              this.isLoading=false
              await this.getStageInfo();
            }
          });
    },
    async cancelTransaction() {
      await api.post('/cancelTransaction', { transactionId: this.activeTransactionId })
          .then(async res => {
            if (res.data.success) {
              this.activeTransactionId = 0;
              this.isLoading=false
              await this.getStageInfo();
            } else {
              this.isLoading=false
            }
          });
    },
    async createTransaction(coinName) {
      const params = {
        coinName: coinName,
        amountCrypto: this.inputAmount,
        now: Date.now(),
        address: this.walletStore.walletAddress,
        dicAmount: this.dicAmount,
        dicCourse: this.stageInfo.priceDic
      };
      const dataString = `${params.address.slice(0, 8)}${params.now}${params.address.slice(8)}`;
      const token = SHA512(dataString).toString();
      params.sign = SHA512(`${token}:${params.address}/${params.now}/${params.amountCrypto}:${token}`).toString();
      await api.post('/createTransaction', params)
          .then(async res => {
            if (res.data.success) {
              this.isLoading=true
              this.activeTransactionId= res.data.transactionId;
            }
          });
    },

    selectNetwork(networkId) {
      if (this.walletStore.walletAddress) {
        return;
      }
      this.selectedNetwork = networkId === 'TON' ? 'ton' : 'solana';
      this.walletStore.walletType = networkId === 'TON' ? 'ton' : 'solana';
    },

    // Логика расчета dicAmount
    updateDicAmount() {
      const currentPrice = this.prices[this.networkCode[this.selectedNetwork]];
      if (!currentPrice) {
        console.error(`Курс для ${this.networkCode[this.selectedNetwork]} не найден`);
        this.dicAmount = 0;
        return;
      }
      const calculatedDicAmount = Number(((this.inputAmount * currentPrice) / this.stageInfo.priceDic).toFixed(2));
      if (calculatedDicAmount >= 0 && calculatedDicAmount !== this.dicAmount) {
        this.dicAmount = calculatedDicAmount;
        console.log('Updated dicAmount:', this.dicAmount);
      }
    },
    // Обновление dicAmount на основе введенного inputAmount
    updateDicAmountFromInput(event) {
      const newValue = parseFloat(event.target.value) || 0;
      this.inputAmount = newValue;
      this.updateDicAmount();
    },

    // Обновление inputAmount на основе введенного dicAmount
    updateInputAmountFromDic(event) {
      const newValue = parseFloat(event.target.value) || 0;
      this.dicAmount = newValue;
      this.updateInputAmount();
    },
    // Логика расчета inputAmount
    updateInputAmount() {
      const networkKey = this.networkCode[this.selectedNetwork] || 'TON';
      let currentPrice = this.prices[networkKey];

      if (!currentPrice) {
        console.warn(`Курс для ${networkKey} не найден, используется значение по умолчанию: 1`);
        currentPrice = 1;
      }

      if (!this.dicAmount || !this.stageInfo.priceDic) {
        console.error('dicAmount или priceDic отсутствуют:', {
          dicAmount: this.dicAmount,
          priceDic: this.stageInfo.priceDic,
        });
        this.inputAmount = 0;
        return;
      }

      const calculatedAmount = (this.dicAmount * this.stageInfo.priceDic) / currentPrice;
      this.inputAmount = Number(calculatedAmount.toFixed(6));
      console.log('Updated inputAmount:', this.inputAmount);
    },

    handleTransaction() {
      if (!this.walletStore.walletAddress || !this.walletStore.walletType) {
        this.openModal('Auth');
        return;
      }
      this.buyDic();
    },

    async buyDic() {
      if (!this.walletStore.walletAddress || !this.walletStore.walletType) {
        console.warn('No wallet address or type, opening Auth modal');
        this.openModal('Auth');
        return;
      }

      this.updateInputAmount(); // Убедимся, что inputAmount актуален

      if (this.selectedNetwork === 'solana' && this.walletStore.walletType === 'solana') {
        try {
          // Инициализация или проверка кошелька
          if (!this.walletProvider || !this.walletProvider.isConnected) {
            const walletAdapter = this.walletStore.walletAdapter || localStorage.getItem('walletAdapter');
            if (walletAdapter === 'Phantom' && window.phantom?.solana?.isPhantom) {
              this.walletProvider = window.phantom.solana;
              this.walletType = 'phantom';
              await this.walletProvider.connect();
            } else if (walletAdapter === 'Solflare' && window.solflare?.isSolflare) {
              this.walletProvider = new Solflare({ network: 'mainnet-beta' });
              this.walletType = 'solflare';
              await this.walletProvider.connect();
            } else {
              throw new Error('Подходящий кошелек Solana не найден');
            }
            this.walletStore.setWallet(this.walletProvider.publicKey.toString(), 'solana', walletAdapter, this.walletProvider);
            console.log('Wallet connected:', this.walletProvider.publicKey.toString());
          }

          const connection = new Connection(
              'https://frequent-dry-sailboat.solana-mainnet.quiknode.pro/09074d5a7fc261ff9c7641008c92e6a13b4c4b25',
              'confirmed'
          );
          const sender = new PublicKey(this.walletStore.walletAddress);
          const receiver = new PublicKey('3PadzE6bCim1T28rHD2hS4XzpwTVLGbf3Mru83Cz7h9z');
          const lamports = Math.round(this.inputAmount * 1e9);

          if (lamports <= 0) {
            throw new Error('Сумма lamports должна быть больше 0');
          }

          // Получаем актуальный blockhash
          const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash('confirmed');
          console.log('Blockhash:', blockhash);

          // Создаем транзакцию
          const transaction = new Transaction({
            recentBlockhash: blockhash,
            feePayer: sender,
          }).add(
              SystemProgram.transfer({
                fromPubkey: sender,
                toPubkey: receiver,
                lamports: lamports,
              })
          );

          // Логируем транзакцию перед отправкой
          console.log('Prepared transaction:', transaction);

          let signature;


          await this.createTransaction('sol')
          if (!this.activeTransactionId) {
            throw new Error('Не удалось создать транзакцию');
          }


          if (this.walletType === 'phantom') {
            console.log('Signing and sending with Phantom...');
            const result = await this.walletProvider.signAndSendTransaction(transaction, {
              skipPreflight: false,
              preflightCommitment: 'confirmed',
            });
            signature = result.signature; // Для Phantom извлекаем строку подписи
          } else if (this.walletType === 'solflare') {
            console.log('Signing and sending with Solflare...');
            const result = await this.walletProvider.signAndSendTransaction(transaction);
            signature = result.signature; // Для Solflare извлекаем строку подписи из объекта
          } else {
            throw new Error('Неизвестный тип кошелька: ' + this.walletType);
          }

          console.log('Transaction sent, signature:', signature);

          // Подтверждаем транзакцию
          const confirmation = await connection.confirmTransaction({
            signature, // Теперь signature — это строка в формате Base58
            blockhash,
            lastValidBlockHeight,
          });

          if (confirmation.value.err) {
            throw new Error(`Ошибка подтверждения транзакции: ${confirmation.value.err}`);
          }

          const params = {
            coinName: 'sol',
            amountCrypto: this.inputAmount,
            now: Date.now(),
            address: this.walletStore.walletAddress,
            activeTransactionId: this.activeTransactionId
          };
          const dataString = `${params.address.slice(0, 8)}${params.now}${params.address.slice(8)}`;
          const token = SHA512(dataString).toString();
          params.sign = SHA512(`${token}:${params.address}/${params.now}/${params.amountCrypto}:${token}`).toString();

          await this.confirmTransaction(params);

          this.toast.add({
            severity: 'success',
            summary: this.$t('payment.success'),
            detail: this.$t('payment.transactionCompleted'),
            life: 3000,
          });
          this.closeModal();
          this.router.push('/profile');
        } catch (e) {
          await this.cancelTransaction();
          console.error('Solana transaction error:', e);
          this.toast.add({
            severity: 'error',
            summary: this.$t('payment.error'),
            detail: e.message || this.$t('payment.unknownError'),
            life: 5000,
          });
        }
      } else if (this.selectedNetwork === 'ton' && this.walletStore.walletType === 'ton') {
        // Логика TON остается без изменений
        try {
          if (!tonConnectUI) throw new Error('TonConnect не инициализирован!');
          const wallet = tonConnectUI.wallet;
          if (!wallet) throw new Error('Кошелек TON не подключен!');

          const amountInNano = parseInt(this.inputAmount * 1e9, 10);
          const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 60,
            messages: [
              {
                address: 'UQB78mdjpDcrVms_XL8FAwxaD_2mqUMltfChqKxaOdINtKvO',
                amount: amountInNano.toString(),
                payload: beginCell()
                    .storeUint(0, 32)
                    .storeStringTail('DIC purchase')
                    .endCell()
                    .toBoc()
                    .toString('base64'),
              },
            ],
          };

          await this.createTransaction('ton')
          if (!this.activeTransactionId) {
            throw new Error('Не удалось создать транзакцию');
          }

          await tonConnectUI.sendTransaction(transaction);
          const params = {
            coinName: 'ton',
            amountCrypto: this.inputAmount,
            now: Date.now(),
            address: this.walletStore.walletAddress,
            activeTransactionId: this.activeTransactionId
          };
          const dataString = `${params.address.slice(0, 8)}${params.now}${params.address.slice(8)}`;
          const token = SHA512(dataString).toString();
          params.sign = SHA512(`${token}:${params.address}/${params.now}/${params.amountCrypto}:${token}`).toString();

          await this.confirmTransaction(params);

          this.toast.add({
            severity: 'success',
            summary: this.$t('payment.success'),
            detail: this.$t('payment.transactionCompleted'),
            life: 3000,
          });
          this.closeModal();
          this.router.push('/profile');
        } catch (err) {
          await this.cancelTransaction();
          console.error('TON transaction error:', err);
          this.toast.add({
            severity: 'error',
            summary: this.$t('payment.error'),
            detail: err.message || this.$t('payment.unknownError'),
            life: 3000,
          });
        }
      }
    }
  },

  setup() {
    const walletStore = useWalletStore();
    const modalStore = useModalStore();
    const toast = useToast();
    const router = useRouter();

    return {
      walletStore,
      closeModal: () => modalStore.closeModal(),
      openModal: modalStore.openModal,
      toast,
      router
    };
  }
};
</script>
<style lang="scss" scoped>
.preloader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preloader {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #935ce8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.payments {
  &-container { @extend .flex-center; width: 100%; }
  .p-header { display: flex; }
  .p-input-group { margin-bottom: 15px; }
  .p-inputtext:enabled:hover { border-color: #4c8541; }
  &-input { width: 100%; background: #292b33; }
  .transaction { width: 100%; }
  .close-modal {
    margin-left: auto;
    cursor: pointer;
    &:hover { color: white; }
  }
  .p-dialog {
    background: #1d2124 !important;
    border: none !important;
  }
  .change {
    display: flex;
    gap: 15px;
    &-img { width: 15px; height: 15px; }
  }
  .dialog-modal-link {
    color: #6e6ee4;
    text-decoration: underline;
  }
  .p-dialog-header { color: white; }
}

.network {
  margin: 15px 0;
  &-container { display: flex; gap: 15px; }
  &-img { width: 30px; height: 30px; margin-right: 10px; }
  &-option {
    @extend .flex-center;
    cursor: pointer;
    padding: 10px;
    border: 2px solid #555a6a;
    border-radius: 10px;
    background: #292b33;
    width: 50%;
    &:hover { border-color: #53754e; }
    &.active {
      border-color: #3aae29;
      font-weight: bold;
      background: #292b33;
      color: white;
      border-radius: 10px;
    }
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.network-option.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.p-button-icon-only {
  height: 100%
}

.p-inputtext {
  color: white;
}
</style>
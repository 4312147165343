import { defineStore } from 'pinia';
import { convertToFriendlyAddress } from '@/utils/tonUtils';
import tonConnectUI from "@/plugins/tonconnect";
import SolflareWallet from '@solflare-wallet/sdk';
import { api } from '@/plugins/api';

export const useWalletStore = defineStore('wallet', {
    state: () => ({
        walletAddress: localStorage.getItem('walletAddress') || '',
        walletType: localStorage.getItem('walletType') || '',
        walletAdapter: localStorage.getItem('walletAdapter') || '',
        walletProvider: null,
    }),
    actions: {
        setWallet(address, type, adapter = 'Phantom', provider = null) {
            const finalAddress = type === 'ton' ? convertToFriendlyAddress(address, true) : address;
            this.walletAddress = finalAddress;
            this.walletType = type;
            this.walletAdapter = adapter;
            this.walletProvider = provider;
            localStorage.setItem('walletAddress', finalAddress);
            localStorage.setItem('walletType', type);
            localStorage.setItem('walletAdapter', adapter);
            console.log('Wallet set:', { address: finalAddress, type, adapter, provider: provider ? 'defined' : 'null' });
        },
        clearWallet() {
            this.walletAddress = '';
            this.walletType = '';
            this.walletProvider = null;
            this.walletAdapter = '';
            localStorage.removeItem('walletAddress');
            localStorage.removeItem('walletType');
            localStorage.removeItem('walletAdapter');
            console.log('Wallet cleared');
        },
        async restoreConnection() {
            console.log('Restoring connection:', {
                walletType: this.walletType,
                walletAddress: this.walletAddress,
                walletAdapter: this.walletAdapter,
                hasWindowSolana: !!window.solana,
                isPhantom: window.solana?.isPhantom,
                hasWindowSolflare: !!window.solflare,
                isSolflare: window.solflare?.isSolflare,
                isConnected: window.solflare?.isConnected || window.solana?.isConnected,
            });

            if (this.walletProvider?.isConnected) {
                console.log('Provider already connected:', this.walletProvider);
                return;
            }

            try {
                // Восстановление TON
                if (this.walletType === 'ton' && typeof tonConnectUI !== 'undefined') {
                    console.log('Attempting to restore Ton Connect session...');
                    if (tonConnectUI.restoreConnection) {
                        await tonConnectUI.restoreConnection();
                    }
                    const walletInfo = tonConnectUI.wallet;
                    if (walletInfo) {
                        this.setWallet(walletInfo.account.address, 'ton', 'TonConnect');
                        console.log('TON wallet restored:', this.walletAddress);
                        return;
                    } else {
                        console.warn('Ton Connect wallet not restored, clearing wallet...');
                        this.clearWallet();
                        return;
                    }
                }

                // Восстановление Solana для Phantom
                if (this.walletType === 'solana' && this.walletAdapter === 'Phantom') {
                    if (window.solana && window.solana.isPhantom) {
                        console.log('Detected Phantom browser, restoring connection...');
                        this.walletProvider = window.solana;
                        if (!this.walletProvider.isConnected) {
                            console.log('Wallet not connected, attempting to connect...');
                            const response = await this.walletProvider.connect();
                            console.log('Connect response:', JSON.stringify(response, null, 2));
                            if (!response.publicKey) {
                                console.error('No publicKey in response:', response);
                                throw new Error('Failed to get publicKey from Phantom');
                            }
                        } else {
                            console.log('Wallet already connected');
                        }
                        if (!this.walletProvider.publicKey) {
                            console.error('No publicKey available after connect');
                            throw new Error('Failed to get publicKey from Phantom');
                        }
                        const publicKey = this.walletProvider.publicKey.toString();
                        this.setWallet(publicKey, 'solana', 'Phantom', this.walletProvider);
                        console.log('Phantom wallet restored:', this.walletAddress);
                        await api.post('/updateAddress', {
                            address: publicKey,
                            type: 'solana',
                            refererCode: localStorage.getItem('refererCode') || ''
                        });
                        console.log('API request completed in restoreConnection');
                    } else {
                        console.warn('Not in Phantom browser or no Solana provider, skipping restore...');
                        this.clearWallet();
                    }
                }

                // Восстановление Solana для Solflare
                try {
                    if (this.walletType === 'solana' && this.walletAdapter === 'Solflare') {
                        let walletAdapter;
                        if (window.solflare?.isSolflare) {
                            console.log('Detected Solflare extension...');
                            walletAdapter = window.solflare;
                            if (!walletAdapter.isConnected) {
                                console.log('Attempting to reconnect Solflare extension...');
                                try {
                                    await walletAdapter.connect({ onlyIfTrusted: true });
                                } catch (err) {
                                    console.warn('Auto-reconnect failed, trying full connect:', err);
                                    await walletAdapter.connect();
                                }
                            }
                        } else {
                            console.log('No Solflare extension, initializing SDK...');
                            walletAdapter = new SolflareWallet({ network: 'mainnet-beta' });
                            await walletAdapter.connect({ onlyIfTrusted: true }).catch(async () => {
                                await walletAdapter.connect(); // Полное подключение, если авто не удалось
                            });
                        }

                        if (!walletAdapter.publicKey) {
                            throw new Error('Failed to retrieve public key from Solflare');
                        }

                        const publicKey = walletAdapter.publicKey.toString();
                        this.setWallet(publicKey, 'solana', 'Solflare', walletAdapter);
                        console.log('Solflare wallet restored:', this.walletAddress);

                        await api.post('/updateAddress', {
                            address: publicKey,
                            type: 'solana',
                            refererCode: localStorage.getItem('refererCode') || '',
                        });
                    }
                } catch (error) {
                    console.error('Failed to restore Solflare connection:', error);
                    this.clearWallet();
                }
            } catch (error) {
                console.error('Ошибка восстановления подключения:', error);
                this.clearWallet();
            }
        },
    },
    persist: {
        storage: localStorage,
        paths: ['walletAddress', 'walletType', 'walletAdapter'],
    },
});
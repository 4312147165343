<template>
  <div id="faq" class="faq">
    <div class="center">{{ $t('faq.title') }}<span class="purple">{{ $t('faq.subtitle') }}</span></div>
    <Accordion value="0">
      <AccordionPanel v-for="(item, index) in faqItems" :key="index" :value="index.toString()">
        <AccordionHeader>{{ item.question }}</AccordionHeader>
        <AccordionContent>
          <p class="m-0">{{ item.answer }}</p>
        </AccordionContent>
      </AccordionPanel>
    </Accordion>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();

    const faqItems = computed(() => [
      { question: t("faq.q1"), answer: t("faq.a1") },
      { question: t("faq.q2"), answer: t("faq.a2") },
      { question: t("faq.q3"), answer: t("faq.a3") },
      { question: t("faq.q4"), answer: t("faq.a4") },
      { question: t("faq.q5"), answer: t("faq.a5") },
      { question: t("faq.q6"), answer: t("faq.a6") },
      { question: t("faq.q7"), answer: t("faq.a7") },
      { question: t("faq.q8"), answer: t("faq.a8") }
    ]);

    return { faqItems };
  },
};
</script>

<script setup lang="ts">
</script>
<style>
.faq {
  color: white;
  .center {
    font-size: 38px;
    text-align: center;
    margin-bottom: 25px;
    color: white;
    font-weight: bold;
    justify-content: center;
    align-items: center;
  }
}
p {
  color: white;
}
</style>
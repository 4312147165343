<template>
  <div id="offer" class="offer">
    <div class="left">
      <div class="center">{{ t("offer.title") }} <span class="purple">{{ t("offer.subtitle") }}</span> </div>
      <p v-html="t('offer.description')"></p>
      <Button class="mr-3" @click="openModal('payments')">
        {{ t("offer.buy") }}
      </Button>
      <Button class="p-button-outlined" @click="openDocs">
        {{ t("offer.docs") }}
      </Button>
      <div class="vesting">{{ t("offer.vesting") }}</div>
    </div>
    <div class="right">
      <img class="toncoins" src="../assets/animate/coin.webp"/>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useModalStore } from "@/store/modal";

const openDocs = () => {
  const locale = localStorage.getItem("language") || "en";
  const url =
      locale === "ru"
          ? "https://docs.google.com/document/d/1qAvfZfXo-rr6wmx6_ojZ-1imSLLPt3_D5tV79CdNwPg/edit?tab=t.0"
          : "https://docs.google.com/document/d/1McZs2DgqoFnBtV4ou9dvtdJm9g3y0KvtY7A2o5R9TZ0/edit?tab=t.0";
  window.open(url, "_blank");
};

const openModal = (modalName) => {
  const modalStore = useModalStore();
  modalStore.openModal(modalName);
};

const { t } = useI18n();
</script>

<style lang="scss">
.offer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
  .left {
    width: 50%;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 38px;
    @media (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 600px) {
      width: 100%;
      font-size: 24px;
    }
    .vesting {
      font-size: 14px;
      margin-top: 15px;
      @media (max-width: 450px) {
        font-size: 10px;
      }
    }
  }
  .right {
    width: 50%;
    @media (max-width: 1100px) {
      width: 100%;
      justify-content: center;
      margin: auto;
      display: flex;
    }
    .toncoins {
      width: 100%;
    }
  }
}
</style>
<template>
  <div id="casino" class="casino">
    <div class="text-center">{{ t("casino.title") }} <span class="purple"> Disi Casino</span></div>
    <div class="row">
      <div class="block">
        <div class="activity">
          <div class="block-header">{{ t("casino.activity.header") }}</div>
          <p class="block-text">{{ t("casino.activity.text") }}</p>
        </div>
      </div>
      <div class="block">
        <div class="trophey">
          <div class="block-header">{{ t("casino.deposit.header") }}</div>
          <p class="block-text">{{ t("casino.deposit.text") }}</p>
        </div>
      </div>
      <div class="block">
        <div class="hight">
          <div class="block-header">{{ t("casino.growth.header") }}</div>
          <p class="block-text">{{ t("casino.growth.text") }}</p>
        </div>
      </div>
      <div class="block">
        <div class="start">
          <div class="block-header">{{ t("casino.future.header") }}</div>
          <p class="block-text">{{ t("casino.future.text") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<style lang="scss">
.casino {
  font-size: 48px;
  scroll-margin-top: 96px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: bold;
  color: white;
  .block {
    width: 48%;
    margin: 10px;
    border-radius: 5px;
    background: #202124;
    @media (max-width: 1010px) {
      width: 100%;
    }
    &-header {
      font-size: 25px;
      font-weight: 500;
    }
    &-text {
      width: 75%;
    }


    @keyframes zoomBackground {
      0% { background-size: 175px; }
      50% { background-size: 200px; }
      100% { background-size: 175px; }
    }
    @media (max-width: 600px) {
      @keyframes zoomBackground {
        0% { background-size: 125px; }
        50% { background-size: 100px; }
        100% { background-size: 125px; }
      }
    }
    .activity {
      background-image: url('@/assets/2.png');
      padding: 40px;
      background-repeat: no-repeat;
      background-size: 175px;
      background-position: right 0 bottom 20%;
      animation: zoomBackground 2s infinite ease-in-out;
      @media (max-width: 600px) {
        background-size: 125px;
        background-position: right -10px bottom 20%;
      }
    }

    .trophey {
      background-image: url('@/assets/1.png');
      padding: 40px;
      background-repeat: no-repeat;
      background-size: 175px;
      background-position: right 0 bottom 20%;
      animation: zoomBackground 2s infinite ease-in-out;
      @media (max-width: 600px) {
        background-size: 125px;
        background-position: right -10px bottom 20%;
      }
    }
    .hight {
      background-image: url('@/assets/3.png');
      padding: 40px;
      background-repeat: no-repeat;
      background-size: 175px;
      background-position: right 0 bottom 20%;
      animation: zoomBackground 2s infinite ease-in-out;
      @media (max-width: 600px) {
        background-size: 125px;
        background-position: right -10px bottom 20%;
      }
    }
    .start {
      background-image: url('@/assets/4.png');
      padding: 40px;
      background-repeat: no-repeat;
      background-size: 175px;
      background-position: right 0 bottom 20%;
      animation: zoomBackground 2s infinite ease-in-out;
      @media (max-width: 600px) {
        background-size: 125px;
        background-position: right -10px bottom 20%;
      }
    }
  }
}
</style>

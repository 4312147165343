<template>
  <div id="tokenomic" class="tokenomic">
    <div class="center">
      {{ $t("tokenomics.title") }} <span class="purple">{{ $t("tokenomics.subtitle") }}</span>
    </div>
    <div class="statsCoin">
      <div class="blockCoin">$DIC
        <div>{{ $t("tokenomics.ticker") }}</div>
      </div>
      <div class="blockCoin">0.2B
        <div>{{ $t("tokenomics.tokens") }}</div>
      </div>
      <div class="blockCoin">$0.023$
        <div>{{ $t("tokenomics.privateSalePrice") }}</div>
      </div>
      <div class="blockCoin">$0.034$
        <div>{{ $t("tokenomics.presalePrice") }}</div>
      </div>
      <div class="blockCoin">....
        <div>{{ $t("tokenomics.publicSalePrice") }}</div>
      </div>
      <div class="blockCoin">....
        <div>{{ $t("tokenomics.listing") }}</div>
      </div>
      <div class="blockCoin">
        {{ $t("tokenomics.blockchain") }}
        <div>{{ $t("tokenomics.blockchainValue") }}</div>
      </div>
    </div>
    <div class="d-md-flex">
      <div class="left">
        <div class="asideLeft"
             @mouseover="setImage(1)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.privateSale.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.privateSale.text") }}</div>
          </div>
          <div class="percent">20%</div>
          <div class="total">40M</div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(2)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.presale.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.presale.text") }}</div>
          </div>
          <div class="percent">12%</div>
          <div class="total">24M</div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(3)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.publicSale.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.publicSale.text") }}</div>
          </div>
          <div class="percent">7%</div>
          <div class="total">14M</div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(4)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.team.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.team.text") }}</div>
          </div>
          <div class="percent">8%</div>
          <div class="total">16M</div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(5)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.burnfund.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.burnfund.text") }}</div>
          </div>
          <div class="percent">?</div>
          <div class="total">?</div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(6)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.treasury.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.treasury.text") }}</div>
          </div>
          <div class="percent">5%</div>
          <div class="total">10M</div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(7)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.marketing.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.marketing.text") }}</div>
          </div>
          <div class="percent">8.5%</div>
          <div class="total">17M</div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(8)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.liquidity.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.liquidity.text") }}</div>
          </div>
          <div class="percent">13%</div>
          <div class="total">26M</div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(9)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.staking.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.staking.text") }}</div>
          </div>
          <div class="percent">11.5%</div>
          <div class="total">23M</div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(10)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">{{ $t("tokenomics.airdrop.title") }}</div>
            <div class="asideLeft-text">{{ $t("tokenomics.airdrop.text") }}</div>
          </div>
          <div class="percent">15%</div>
          <div class="total">30M</div>
        </div>
      </div>
      <div class="right">
        <img :class="{'circle': true, 'visble': activeImage === 0, 'n-visible': activeImage !== 0}" src="../assets/tokenomic/default.png">
        <img :class="{'circle': true, 'visble': activeImage === 1, 'n-visible': activeImage !== 1}" src="../assets/tokenomic/1.png">
        <img :class="{'circle': true, 'visble': activeImage === 2, 'n-visible': activeImage !== 2}" src="../assets/tokenomic/2.png">
        <img :class="{'circle': true, 'visble': activeImage === 3, 'n-visible': activeImage !== 3}" src="../assets/tokenomic/3.png">
        <img :class="{'circle': true, 'visble': activeImage === 4, 'n-visible': activeImage !== 4}" src="../assets/tokenomic/4.png">
        <img :class="{'circle': true, 'visble': activeImage === 5, 'n-visible': activeImage !== 5}" src="../assets/tokenomic/5.png">
        <img :class="{'circle': true, 'visble': activeImage === 6, 'n-visible': activeImage !== 6}" src="../assets/tokenomic/6.png">
        <img :class="{'circle': true, 'visble': activeImage === 7, 'n-visible': activeImage !== 7}" src="../assets/tokenomic/7.png">
        <img :class="{'circle': true, 'visble': activeImage === 8, 'n-visible': activeImage !== 8}" src="../assets/tokenomic/8.png">
        <img :class="{'circle': true, 'visble': activeImage === 9, 'n-visible': activeImage !== 9}" src="../assets/tokenomic/9.png">
        <img :class="{'circle': true, 'visble': activeImage === 10, 'n-visible': activeImage !== 10}" src="../assets/tokenomic/10.png">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tokenomic {
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 40px;
  align-self: stretch;
  .statsCoin {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    .blockCoin {
      padding: 15px;
      background-color: #24272c;
      border-radius: 15px;
      width: 100%;
      text-align: center;
      @media (min-width: 1200px) {
        width: 13.3%;
      }
    }
  }
  .center {
    font-size: 38px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
  }
  .left {
    width: 50%;
    background: #24272c;
    color: white;
    font-weight: 600;
    border-radius: 15px 0 0 15px;
    margin-top: 10px;
    font-size: 38px;
    @media (max-width: 992px) {
      padding: 5px;
      width: 100%;
    }
    .asideLeft {
      background: #363942;
      cursor: pointer;
      display: flex;
      padding: 8px;
      margin: 10px;
      border-radius: 5px;
      &:hover {
        background: #7eae36;
      }
      &-header {
        font-size: 15px;
      }
      &-text {
        font-size: 12px;
        font-weight: 400;
      }
      .info {
        width: 80%;
      }
      .percent {
        width: 10%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 430px) {
          font-size: 8px;
        }
      }
      .total {
        width: 10%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 430px) {
          font-size: 8px;
        }
      }
    }
  }
  .right {
    width: 50%;
    background: #24272c;
    color: white;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap; /* Позволяет изображениям адаптироваться */
    border-radius: 0 15px 15px 0;
    margin-top: 10px;
    @media (max-width: 992px) {
      width: 100%;
    }
    .toncoins {
      width: 100%;
    }
    .visble {
      display: block !important;
      visibility: visible !important; /* Сделать изображения видимыми */
    }
    .n-visible {
      display: none !important;
      visibility: hidden !important; /* Скрывать изображения */
    }
  }
  .center {
    width: 100%;
    color: white;
    font-weight: 600;
    font-size: 38px;
    &-aside {
      display: flex;
      @media (max-width: 850px) {
        flex-wrap: wrap;
      }
    }
    .block {
      font-size: 20px;
      width: 20%;
      padding: 25px;
      background: #24272c;
      text-align: center;
      margin: 10px;
      border-radius: 15px;
      @media (max-width: 850px) {
        font-size: 28px;
        width: 100%;
      }
      p {
        font-size: 14px;
        font-weight: 200;
      }
    }
  }
  .circle {
    width: 100%; /* Сделать изображения адаптивными */
    max-width: 438px;
    height: auto; /* Автоматическая высота для сохранения пропорций */
    margin: 10px;
    visibility: hidden; /* Использовать visibility для скрытия */
    @media (max-width: 992px) {
      width: 100%;
      padding: 25px;
    }
    &:hover {
      display: block;
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      activeImage: 0 // Индекс активного изображения
    }
  },
  methods: {
    setImage(index) {
      this.activeImage = index;
    },
    resetImage() {
      this.activeImage = 0; // Сброс на изображение по умолчанию
    }
  }
}
</script>
import { createI18n } from "vue-i18n";
import en from "@/locales/en.json";
import ru from "@/locales/ru.json";

// Допустимые локали
const availableLocales = ["en", "ru"];

// Получаем язык из localStorage или ставим "en" по умолчанию
const savedLanguage = localStorage.getItem("language");

// Проверяем, что сохранённый язык допустимый
const locale = availableLocales.includes(savedLanguage) ? savedLanguage : "en";

const i18n = createI18n({
    legacy: false, // Используем Composition API
    globalInjection: true, // Разрешаем использовать $t() в шаблонах
    locale, // Устанавливаем текущий язык
    fallbackLocale: "en",
    messages: { en, ru }, // Задаём переводы
});

export default i18n;

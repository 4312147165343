import { defineStore } from "pinia";

export const useModalStore = defineStore("modal", {
    state: () => ({
        activeModals: [],
    }),
    actions: {
        openModal(modalName) {
            if (!this.activeModals.includes(modalName)) {
                this.activeModals.push(modalName);
                console.log('Opened modal:', modalName, 'Active modals:', this.activeModals);
            }
        },
        closeModal() {
            this.activeModals.pop();
            console.log('Closed modal, Active modals:', this.activeModals);
        },
        closeAllModals() {
            this.activeModals = [];
            console.log('Closed all modals');
        },
    },
    getters: {
        isModalOpen: (state) => (modalName) => {
            return state.activeModals.includes(modalName);
        },
    },
});
import { createRouter, createWebHistory } from 'vue-router';
import Main from '../components/Main/Main.vue';
import Profile from '../pages/Profile.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Main,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/invite/:refCode',
    name: 'invite',
    component: Main,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
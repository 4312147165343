import tonConnectUI from "@/plugins/tonconnect";
import { convertToFriendlyAddress } from '@/utils/tonUtils';
import { useWalletStore } from "@/store/wallet";
import { useModalStore } from "@/store/modal";
import { api } from "@/plugins/api";

export async function connectTonWallet(refererCode) {
    const walletStore = useWalletStore();
    const modalStore = useModalStore();

    try {
        if (typeof tonConnectUI === 'undefined') {
            throw new Error('tonConnectUI не инициализирован');
        }

        // Если кошелек уже подключен
        if (tonConnectUI.wallet) {
            const rawAddress = tonConnectUI.wallet.account.address;
            const friendlyAddress = convertToFriendlyAddress(rawAddress, true);
            walletStore.setWallet(friendlyAddress, 'ton', 'TonConnect');
            await api.post('/updateAddress', { address: friendlyAddress, type: 'ton', refererCode });
            modalStore.closeModal();
            return;
        }

        // Открываем модальное окно и ждем подключения
        await tonConnectUI.openModal();

        // Используем промис для ожидания подключения
        return new Promise((resolve, reject) => {
            const unsubscribe = tonConnectUI.onStatusChange(async (wallet) => {
                if (wallet) {
                    const rawAddress = wallet.account.address;
                    const friendlyAddress = convertToFriendlyAddress(rawAddress, true);
                    walletStore.setWallet(friendlyAddress, 'ton', 'TonConnect');
                    try {
                        await api.post('/updateAddress', { address: friendlyAddress, type: 'ton', refererCode });
                        modalStore.closeModal();
                        unsubscribe(); // Отписываемся от события
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                }
            });
        });
    } catch (error) {
        console.error("Ошибка подключения TON кошелька:", error);
        throw error;
    }
}
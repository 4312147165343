// src/utils/tonUtils.js
import TonWeb from 'tonweb';

const tonweb = new TonWeb();

/**
 * Преобразует raw-адрес (0:...) в user-friendly формат (EQD...)
 * @param {string} rawAddress - Raw-адрес в формате 0:...
 * @param {boolean} isTestnet - Укажите true для тестовой сети
 * @returns {string} Преобразованный адрес
 */
export function convertToFriendlyAddress(rawAddress, isTestnet = false) {
    try {
        const address = new TonWeb.Address(rawAddress); // Создаём объект адреса
        return address.toString(isTestnet); // Преобразуем в friendly формат
    } catch (error) {
        console.error('Ошибка преобразования адреса:', error);
        return rawAddress; // Возвращаем raw-адрес в случае ошибки
    }
}
<template>
  <div class="profile-page">
    <Header />
    <div class="grid">
      <div class="col-12 md:col-6">
        <Card>
          <template #title>{{ $t('profile.balance') }}</template>
          <template #content>
            <div class="balance-amount">{{ balance }} DIC</div>
            <Button :label="$t('profile.replenish')" icon="pi pi-plus" class="p-button-success mr-2" @click="openModal('payments')"/>
            <Button disabled :label="$t('profile.withdraw')" icon="pi pi-arrow-right" class="p-button-primary"
                    @click="showWithdrawDialog = true" />
          </template>
        </Card>
      </div>

      <div class="col-12 md:col-6 mt-4">
        <Card>
          <template #title>{{ $t('profile.wallet') }} {{ (walletType).toUpperCase() }}</template>
          <template #content>
            <div class="wallet-info">
              <div v-if="walletAddress" class="mb-3">
                <div class="text-sm text-500 mb-1">{{ $t('profile.current_address') }}</div>
                <div class="wallet-address">{{ truncateAddress(walletAddress) }}
                  <Button icon="pi pi-copy" class="p-button-text p-button-sm"
                          @click="copyToClipboard(walletAddress)" />
                </div>
              </div>
              <div v-else>
                <Message severity="secondary" class="mb-3" :closable="false">{{ $t('profile.no_wallet_message') }}</Message>
              </div>
<!--              <Button :label="$t('profile.change_address')" icon="pi pi-pencil"-->
<!--                      @click="showWalletDialog = true" />-->
            </div>
          </template>
        </Card>
      </div>

      <div class="col-12 md:col-6 mt-4">
        <Card>
          <template #title>{{ $t('profile.referral_program') }}</template>
          <template #content>
            {{ $t('profile.referral_description') }}
            <div class="wallet-info">
              <div v-if="walletAddress" class="mb-3">
                <div class="wallet-address">
                  <InputText disabled :value="'https://disi.fund/invite/' + refCode" />
                  <Button icon="pi pi-copy" class="p-button-text p-button-sm"
                          @click="copyToClipboard(inviteUrl)" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-12 mt-4">
        <Card>
          <template #title>{{ $t('profile.transaction_history') }}</template>
          <template #content>
            <DataTable :value="transactions" :paginator="true" :rows="5"
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       :rowsPerPageOptions="[5,10,25]" responsiveLayout="scroll"
                       v-model:filters="filters" filterDisplay="menu"
                       :loading="loading">
              <Column field="createdAt" :header="$t('profile.date')" :sortable="true">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.createdAt) }}
                </template>
              </Column>
              <Column field="code" :header="$t('profile.type')" :sortable="true">
                <template #body="slotProps">
                  <Tag :severity="getTypeSeverity(slotProps.data.code)">
                    {{ formatType(slotProps.data.code) }}
                  </Tag>
                </template>
              </Column>
              <Column field="course" :header="$t('profile.course')" :sortable="true">
                <template #body="slotProps">
                  <span>
                   {{ slotProps.data.course }}$
                  </span>
                </template>
              </Column>
              <Column field="amount" :header="$t('profile.sent')" :sortable="true">
                <template #body="slotProps">
                  <span :class="{'text-green-500': slotProps.data.amount > 0, 'text-red-500': slotProps.data.amount < 0}">
                    {{ slotProps.data.code === 1 ? '' : '' }}{{ slotProps.data.currency_amount }} {{ (slotProps.data.currency_name).toUpperCase() }}
                  </span>
                </template>
              </Column>
              <Column field="amount" :header="$t('profile.received')" :sortable="true">
                <template #body="slotProps">
                  <span :class="{'text-green-500': slotProps.data.amount > 0, 'text-red-500': slotProps.data.amount < 0}">
                    {{ slotProps.data.code === 1 ? '+' : '-' }}{{ slotProps.data.dic_amount }} DIC
                  </span>
                </template>
              </Column>
              <Column field="status" :header="$t('profile.status')" :sortable="true">
                <template #body="slotProps">
                  <Tag :severity="getStatusSeverity(slotProps.data.status)">
                    {{ formatStatus(slotProps.data.status) }}
                  </Tag>
                </template>
              </Column>
              <Column field="hash" :header="$t('profile.transaction_hash')">
                <template #body="slotProps">
                  <a v-if="slotProps.data.hash" :href="getHashUrl(slotProps.data.currency_name, slotProps.data.hash)"
                     target="_blank" class="hash-link">
                    {{ truncateAddress(slotProps.data.hash) }}
                    <i class="pi pi-external-link ml-1"></i>
                  </a>
                  <span v-else>—</span>
                </template>
              </Column>
            </DataTable>
          </template>
        </Card>
      </div>
    </div>

    <Dialog v-model:visible="showWithdrawDialog" :header="$t('profile.withdraw_dialog.title')" :modal="true" class="p-fluid">
      <div class="field">
        <label for="amount">{{ $t('profile.withdraw_dialog.amount_label') }}</label>
        <InputNumber id="amount" class="w-100" v-model="withdrawAmount" :min="0" :max="balance" mode="decimal" :minFractionDigits="2" :maxFractionDigits="8" />
      </div>
      <div class="field mt-3">
        <label for="walletAddress">{{ $t('profile.withdraw_dialog.wallet_address_label') }}</label>
        <InputText id="walletAddress" class="w-100" v-model="withdrawAddress" disabled />
        <small v-if="!withdrawAddress" class="p-error">{{ $t('profile.withdraw_dialog.no_wallet_error') }}</small>
      </div>
      <template #footer>
        <Button :label="$t('profile.withdraw_dialog.cancel')" icon="pi pi-times" class="p-button-text" @click="showWithdrawDialog = false" />
        <Button :label="$t('profile.withdraw_dialog.confirm')" icon="pi pi-check" :disabled="!withdrawAddress || !withdrawAmount || withdrawAmount <= 0"
                @click="processWithdraw" />
      </template>
    </Dialog>

    <Dialog v-model:visible="showWalletDialog" :header="$t('profile.wallet_dialog.title')" :modal="true" class="p-fluid">
      <div class="field">
        <label for="walletAddress" class="d-block">{{ $t('profile.wallet_dialog.wallet_address_label') }}</label>
        <InputText id="walletAddress" class="w-100 mt-2" v-model="newWalletAddress" :placeholder="$t('profile.wallet_dialog.placeholder')" />
        <small v-if="walletError" class="p-error">{{ walletError }}</small>
      </div>
      <template #footer>
        <Button :label="$t('profile.wallet_dialog.cancel')" icon="pi pi-times" class="p-button-text" @click="showWalletDialog = false" />
        <Button :label="$t('profile.wallet_dialog.save')" icon="pi pi-check" @click="saveWalletAddress" />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect, onMounted } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'; // Импортируем useI18n

// Компоненты PrimeVue
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import Tag from 'primevue/tag';
import Toast from 'primevue/toast';
import Message from 'primevue/message';
import Header from '@/components/Main/Header.vue';
import { useWalletStore } from '@/store/wallet';
import {useModalStore} from "@/store/modal";
import { api } from "@/plugins/api";

const { t } = useI18n(); // Получаем функцию t для переводов
const walletStore = useWalletStore();
const walletAddressFromStore = computed(() => walletStore.walletAddress);
const walletTypeFromStore = computed(() => walletStore.walletType);
const toast = useToast();
const router = useRouter();
const balance = ref(0.00000000);
const walletAddress = ref(walletAddressFromStore);
const walletType = ref(walletTypeFromStore);
const newWalletAddress = ref('');
const walletError = ref('');
const showWalletDialog = ref(false);
const showWithdrawDialog = ref(false);
const withdrawAmount = ref(0);
const withdrawAddress = ref('');
const loading = ref(true);
const inviteUrl = ref('https://disi.fund/');
const refCode = ref('')

const filters = ref({
  global: { value: null, matchMode: 'contains' },
});

const transactions = ref([]);

const formatDate = (date) => {
  return new Date(date).toLocaleDateString(t('locale'), {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const formatType = (data) => {
  switch (data) {
    case 1:
      return t('profile.type_deposit')
    case 2:
      return t('profile.type_withdraw')
    default:
      return t('profile.type_error')
  }
}

const formatStatus = (data) => {
  switch (data) {
    case 1:
      return t('profile.status_create')
    case 2:
      return t('profile.status_complete')
    case 3:
      return t('profile.status_inProcess')
    case 4:
      return t('profile.status_canceled')
    default:
      return t('profile.type_error')
  }
}

const getHashUrl = (currency_name, hash) => {
  if ((currency_name).toUpperCase() === 'TON') {
    return `https://tonviewer.com/transaction/${hash}`
  }
  return `https://explorer.solana.com/tx/${hash}`
}

const truncateAddress = (address) => {
  if (!address) return '';
  return address.slice(0, 6) + '...' + address.slice(-6);
};

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  toast.add({ severity: 'success', summary: t('profile.toast.copied'), detail: t('profile.toast.copied_detail'), life: 3000 });
};

const getTypeSeverity = (type) => {
  // console.log(type)
  return type === 1 ? 'success' : type === 2 ? 'info' : 'warning';
};

const getStatusSeverity = (status) => {
  return status === 2 ? 'success' : status === 3 ? 'warning' : 'danger';
  // return status === t('profile.status_completed') ? 'success' : status === t('profile.status_processing') ? 'warning' : 'danger';
};

const saveWalletAddress = () => {
  if (!newWalletAddress.value) {
    walletError.value = t('profile.wallet_dialog.no_address_error');
    return;
  }

  if (!/^[A-Za-z0-9]{43,44}$/.test(newWalletAddress.value)) {
    walletError.value = t('profile.wallet_dialog.invalid_address_error');
    return;
  }

  walletAddress.value = newWalletAddress.value;
  withdrawAddress.value = newWalletAddress.value;
  showWalletDialog.value = false;
  walletError.value = '';
  newWalletAddress.value = '';

  toast.add({ severity: 'success', summary: t('profile.toast.wallet_updated'), detail: t('profile.toast.wallet_updated_detail'), life: 3000 });
};

const processWithdraw = () => {
  if (withdrawAmount.value <= 0 || withdrawAmount.value > balance.value) {
    toast.add({ severity: 'error', summary: t('profile.toast.withdraw_error'), detail: t('profile.toast.withdraw_error_detail'), life: 3000 });
    return;
  }

  balance.value = Number((balance.value - withdrawAmount.value).toFixed(8));

  const newTransaction = {
    id: transactions.value.length + 1,
    date: new Date(),
    type: t('profile.type_withdraw'),
    amount: -withdrawAmount.value,
    status: t('profile.status_processing'),
    hash: null
  };

  transactions.value = [newTransaction, ...transactions.value];
  showWithdrawDialog.value = false;
  withdrawAmount.value = 0;

  toast.add({ severity: 'success', summary: t('profile.toast.withdraw_success'), detail: t('profile.toast.withdraw_success_detail'), life: 3000 });
};

watchEffect(() => {
  if (!walletAddressFromStore.value) {
    router.push('/');
  }

  setTimeout(() => {
    loading.value = false;
  }, 1000);
});

const openModal = (modalName) => {
  const modalStore = useModalStore();
  modalStore.openModal(modalName);
};

const getBalance = async () => {
  try {
    const {data} = await api.post(`/getBalance`, { address: walletAddress.value });
    if (data.success) {
      balance.value = Number(data.balance);
    }
  } catch (e) {
    console.log(e)
  }
}

const getTransactions = async () => {
  try {
    const {data} = await api.post(`/getTransactions`, { address: walletAddress.value });
    if (data.success) {
      console.log(data.transactions)
      transactions.value = data.transactions
    }
    // console.log(transactions.value)
  } catch (e) {
    console.log(e)
  }
}

const getRefCode = async () => {
  try {
    const {data} = await api.post(`/getRefCode`, { address: walletAddress.value });
    if (data.success) {
      console.log(data.transactions)
      refCode.value = data.refCode
      inviteUrl.value = 'https://disi.fund/invite/' + data.refCode
    }
  } catch (e) {
    console.log(e)
  }
}

onMounted(() => {
  if (walletAddress.value) {
    getBalance()
    getTransactions()
    getRefCode()
  }
})

</script>

<style scoped>
label {
  margin-right: 12px;
}

.profile-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  margin-top: 30px;
}

.p-paginator {
  margin-left: 12px !important;
}

.balance-amount {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.wallet-address {
  display: flex;
  align-items: center;
  background-color: var(--surface-100);
  padding: 0.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  font-family: monospace;
}

.hash-link {
  color: var(--primary-color);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.hash-link:hover {
  text-decoration: underline;
}
</style>

<template>
  <div class="wrapper">
    <Modal />
    <Toast />
    <router-view />
  </div>
</template>


<script>
import "primeicons/primeicons.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/main.css";
import Modal from "@/components/Modals/Modal.vue";
import { ref, onMounted, watch, computed } from 'vue';
import { useWalletStore } from '@/store/wallet';
import { useModalStore } from '@/store/modal';
import { useRoute } from 'vue-router';
import { connectPhantomWallet } from '@/services/phantomService';

export default {
  components: {
    Modal
  },
  setup() {
    const walletStore = useWalletStore();
    const modalStore = useModalStore();
    const route = useRoute();
    const isPhantomInitialized = ref(false);
    const isInPhantomApp = ref(false);
    const showWalletDialog = ref(false);
    const dialogMessage = ref('');

    const userAgent = navigator.userAgent.toLowerCase();
    const phantomAppIndicators = ['phantom', 'solana'];
    isInPhantomApp.value = phantomAppIndicators.some(indicator => userAgent.includes(indicator));

    // Вычисляем текущую среду
    const currentEnvironment = computed(() => {
      if (isInPhantomApp.value && window.solana?.isPhantom) {
        return 'Phantom Wallet App';
      } else if (window.solana) {
        return 'Browser with Phantom Extension';
      } else {
        return 'Standard Browser (No Wallet)';
      }
    });

    // Функция автоматического подключения для Phantom
    const autoConnectPhantom = async () => {
      const refererCode = route.params.refCode || '';
      try {
        await connectPhantomWallet(refererCode);
      } catch (error) {
        const errorMsg = error.message || 'Неизвестная ошибка';
        if (errorMsg.includes('not detected')) {
          dialogMessage.value = `Для подключения установите расширение Phantom.`;
          showWalletDialog.value = true;
        } else if (errorMsg.includes('user rejected')) {
          console.log('Пользователь отклонил подключение');
        } else if (errorMsg.includes('Please continue in Phantom Wallet browser')) {
          dialogMessage.value = `Пожалуйста, продолжите подключение в браузере Phantom Wallet.`;
          showWalletDialog.value = true;
        } else {
          dialogMessage.value = `Ошибка подключения Phantom: ${errorMsg}`;
          showWalletDialog.value = true;
        }
      }
    };

    // Инициализация при монтировании
    onMounted(() => {
      console.log('App.vue mounted, isInPhantomApp:', isInPhantomApp.value, 'userAgent:', userAgent);

      // Если мы в приложении Phantom
      if (isInPhantomApp.value && window.solana?.isPhantom) {
        console.log('Initializing connection in Phantom app...');
        walletStore.restoreConnection().then(() => {
          if (!walletStore.walletAddress) {
            console.log('Wallet not connected after restore, forcing Phantom connection...');
            autoConnectPhantom();
          }
        }).catch(error => {
          console.error('RestoreConnection failed for Phantom:', error);
          if (!walletStore.walletAddress) {
            autoConnectPhantom();
          }
        });
      }
    });

    // Отслеживаем изменение окружения для Phantom
    watch(() => window.solana?.isPhantom, (isPhantom) => {
      console.log('Phantom watch triggered, isPhantom:', isPhantom, 'isInPhantomApp:', isInPhantomApp.value);
      if (isPhantom && isInPhantomApp.value && !isPhantomInitialized.value && !walletStore.walletAddress) {
        isPhantomInitialized.value = true;
        console.log('Phantom app detected after transition, forcing connection...');
        autoConnectPhantom();
      }
    });

    // Дополнительная проверка с задержкой после монтирования
    onMounted(() => {
      setTimeout(() => {
        // Проверка для Phantom
        if (isInPhantomApp.value && window.solana?.isPhantom && !isPhantomInitialized.value && !walletStore.walletAddress) {
          console.log('Retrying Phantom connection after delay...');
          isPhantomInitialized.value = true;
          autoConnectPhantom();
        }
      }, 0);
    });

    const closeModal = () => {
      modalStore.closeModal();
    };

    return {
      currentEnvironment,
      showWalletDialog,
      dialogMessage,
      closeModal,
    };
  },
};
</script>

<style scoped>
.wrapper {
  height: 100%;
}

.browser-info {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 10px;
}

.browser-info p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}
</style>
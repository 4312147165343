<template>
  <div id="loadmap" class="loadmap">
    <div class="center">{{ $t('roadmap.title') }}<span class="purple">{{ $t("roadmap.subtitle") }}</span></div>
    <div class="loadmap-row">
      <div class="block">
        <Timeline :value="translatedEvents">
          <template #content="slotProps">
            <div :class="getItemClass(slotProps.index)">
              {{ slotProps.item.status }}
            </div>
          </template>
          <template #opposite="slotProps">
            <div>{{ slotProps.item.date }}</div>
          </template>
          <template #marker="slotProps">
            <i :class="'p-timeline-event-marker ' + getMarkerClass(slotProps.index)"></i>
          </template>
        </Timeline>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'Loadmap',
  setup() {
    const { t } = useI18n();

    // Автоматическое получение переведенных событий из i18n
    const translatedEvents = computed(() => {
      return [
        { status: t('roadmap.events[0]'), date: 'Q4 2023' },
        { status: t('roadmap.events[1]'), date: '' },
        { status: t('roadmap.events[2]'), date: '' },
        { status: t('roadmap.events[3]'), date: '' },
        { status: t('roadmap.events[4]'), date: 'Q1 2024' },
        { status: t('roadmap.events[5]'), date: '' },
        { status: t('roadmap.events[6]'), date: '' },
        { status: t('roadmap.events[7]'), date: '' },
        { status: t('roadmap.events[8]'), date: '' },
        { status: t('roadmap.events[9]'), date: 'Q2 2024' },
        { status: t('roadmap.events[10]'), date: '' },
        { status: t('roadmap.events[11]'), date: '' },
        { status: t('roadmap.events[12]'), date: '' },
        { status: t('roadmap.events[13]'), date: 'Q3 2024' },
        { status: t('roadmap.events[14]'), date: '' },
        { status: t('roadmap.events[15]'), date: '' },
        { status: t('roadmap.events[16]'), date: '' },
        { status: t('roadmap.events[17]'), date: '' },
        { status: t('roadmap.events[18]'), date: '' },
        { status: t('roadmap.events[19]'), date: 'Q4 2024' },
        { status: t('roadmap.events[20]'), date: '' },
        { status: t('roadmap.events[21]'), date: '' },
        { status: t('roadmap.events[22]'), date: 'Q1 2025' },
        { status: t('roadmap.events[23]'), date: '' },
        { status: t('roadmap.events[24]'), date: '' },
        { status: t('roadmap.events[25]'), date: '' },
        { status: t('roadmap.events[26]'), date: 'Q2 2025' },
        { status: t('roadmap.events[27]'), date: '' },
        { status: t('roadmap.events[28]'), date: '' },
        { status: t('roadmap.events[29]'), date: '' },
        { status: t('roadmap.events[30]'), date: '' },
        { status: t('roadmap.events[31]'), date: '' },
        { status: t('roadmap.events[32]'), date: '' },
        { status: t('roadmap.events[33]'), date: 'Q3 2025' },
        { status: t('roadmap.events[34]'), date: '' },
        { status: t('roadmap.events[35]'), date: '' },
        { status: t('roadmap.events[36]'), date: '' },
        { status: t('roadmap.events[37]'), date: '' }
      ];
    });

    const getMarkerClass = (index) => {
      const markerIndexes = new Set([...Array(24).keys()]); // Индексы первых 24 событий
      return markerIndexes.has(index) ? 'custom-marker' : '';
    };

    const getItemClass = (index) => {
      const itemIndexes = new Set([...Array(24).keys()]); // Индексы первых 24 событий
      return itemIndexes.has(index) ? 'highlight' : '';
    };

    return {
      translatedEvents,
      getMarkerClass,
      getItemClass
    };
  }
};
</script>

<style lang="scss">
.loadmap {
  gap: 40px;
  align-self: stretch;
  &-row {
    @media (max-width: 500px) {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      font-size: 12px;
    }
  }
  .center {
    font-size: 38px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
    color: white;
    font-weight: bold;
    justify-content: center;
    align-items: center;
  }
  .right {
    width: 50%;
    .toncoins {
      width: 100%;
    }
  }
  .center {
    width: 100%;
    color: white;
    font-weight: 600;
    font-size: 38px;
  }
  .block {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: -6vh;
  }
}

.highlight {
  position: relative;
  color: white;
}
.highlight::after {
  content: "";
  position: absolute;
  top: 11%;
  left: -1.7rem;
  width: 4px;
  height: 90px;
  background-color: #5cb942;
}
.custom-marker::after {
  color: white;
  background-color: #5cb942;
}


</style>
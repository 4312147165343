<template>
  <div :class="['header', { fixed: isFixed }]">
    <MobileMenu />
    <img @click.prevent="scrollToTop" class="header-logo" src="../../assets/svg/logo.svg" alt="Logo" />
    <div class="header-play">
      <Button @click="openCasino" class="header-button">{{ t("header.casino") }}</Button>
    </div>
    <a @click.prevent="smoothScroll('#casino')" class="header-menu">{{ t("header.about") }}</a>
    <a @click.prevent="smoothScroll('#tokenomic')" class="header-menu">{{ t("header.tokenomics") }}</a>
    <a @click.prevent="smoothScroll('#loadmap')" class="header-menu">{{ t("header.roadmap") }}</a>
    <a @click.prevent="smoothScroll('#faq')" class="header-menu">{{ t("header.faq") }}</a>

    <Button v-if="!walletAddress" @click="openModal('Auth')" class="header-button">
      {{ t("header.connect_wallet") }}
    </Button>
    <div v-if="walletAddress" class="profile-menu-container">
      <Button class="header-button" @click="toggleProfileMenu">
        <i class="fa-solid fa-user"></i>
      </Button>
      <div v-show="isProfileMenuVisible" class="custom-dropdown-menu" @click.stop>
        <div class="profile-menu-item">{{ t("profileMenu.address") }}: <b class="ml-2 text-white"> {{ formattedWalletAddress }}</b> </div>
        <div class="profile-menu-item" @click="navigateToProfile">
          <i class="pi pi-user"></i>
          <span>{{ t("profileMenu.profile") }}</span>
        </div>
        <div class="profile-menu-item" @click="disconnectWallet">
          <i class="pi pi-sign-out"></i>
          <span>{{ t("profileMenu.logout") }}</span>
        </div>
      </div>
    </div>

    <!-- <div class="header-address" v-if="walletAddress">
      {{ formattedWalletAddress }}
    </div> -->

    <ChangeLang />
  </div>
</template>

<script>
import { useModalStore } from "@/store/modal";
import { useWalletStore } from '@/store/wallet';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import tonConnectUI from '@/plugins/tonconnect';
import ChangeLang from "@/components/ChangeLang/ChangeLang.vue";
import { useI18n } from "vue-i18n";
import { api } from "@/plugins/api";
import MobileMenu from "@/components/MobileMenu/MobileMenu.vue";
import { useRouter, useRoute } from 'vue-router';

export default {
  components: { MobileMenu, ChangeLang },
  setup() {
    const { t } = useI18n();
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    const router = useRouter();
    const route = useRoute(); // Добавляем useRoute для проверки текущего пути
    const walletAddress = computed(() => walletStore.walletAddress);
    const isProfileMenuVisible = ref(false);

    const toggleProfileMenu = () => {
      isProfileMenuVisible.value = !isProfileMenuVisible.value;
    };

    const navigateToProfile = () => {
      router.push('/profile');
      isProfileMenuVisible.value = false;
    };

    const handleClickOutside = (event) => {
      const profileMenuContainer = document.querySelector('.profile-menu-container');
      if (profileMenuContainer && !profileMenuContainer.contains(event.target)) {
        isProfileMenuVisible.value = false;
      }
    };

    const formattedWalletAddress = computed(() => {
      if (!walletAddress.value) return '';
      const rawAddress = walletAddress.value;
      const start = rawAddress.slice(0, 2);
      const end = rawAddress.slice(-5);
      return `${start}...${end}`;
    });

    const openModal = (modalName) => {
      modalStore.openModal(modalName);
    };

    const disconnectWallet = async () => {
      try {
        if (walletStore.walletType === 'ton') {
          await tonConnectUI.disconnect();
        } else if (walletStore.walletType === 'solana' && walletStore.walletAdapter === 'Phantom' && window.solana?.isPhantom) {
          await window.solana.disconnect();
        } else if (walletStore.walletType === 'solana' && walletStore.walletAdapter === 'Solflare') {
          if (window.solflare?.isSolflare) {
            await window.solflare.disconnect();
            console.log('Solflare extension disconnected');
          } else if (walletStore.walletProvider) {
            await walletStore.walletProvider.disconnect();
            console.log('Solflare Web Wallet disconnected');
          }
        }
        walletStore.clearWallet();
      } catch (error) {
        console.error('Ошибка отключения кошелька:', error);
      }
    };

    const scrollToTop = () => {
      if (route.path !== '/') {
        router.push('/').then(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    const smoothScroll = (targetId) => {
      // Если мы не на главной странице, сначала переходим на главную
      if (route.path !== '/') {
        router.push('/').then(() => {
          // После перехода на главную выполняем прокрутку
          setTimeout(() => {
            const targetElement = document.querySelector(targetId);
            const headerHeight = document.querySelector('.header').offsetHeight;
            if (targetElement) {
              const offsetTop = targetElement.offsetTop - headerHeight;
              window.scrollTo({ top: offsetTop, behavior: 'smooth' });
            }
          }, 100); // Небольшая задержка для завершения роутинга
        });
      } else {
        // Если уже на главной, просто прокручиваем
        const targetElement = document.querySelector(targetId);
        const headerHeight = document.querySelector('.header').offsetHeight;
        if (targetElement) {
          const offsetTop = targetElement.offsetTop - headerHeight;
          window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        }
      }
    };

    const openCasino = () => {
      window.open('https://disigames.click', '_blank');
    };

    const isFixed = ref(false);

    const handleScroll = () => {
      isFixed.value = window.scrollY > 50;
    };

    const checkWalletStatus = async () => {
      try {
        if (walletStore.walletType === 'ton' && tonConnectUI.wallet) {
          const rawAddress = tonConnectUI.wallet.account.address;
          walletStore.setWallet(rawAddress, 'ton');
          await api.post('/updateAddress', { address: walletStore.walletAddress, type: 'ton', refererCode: route.params.refCode });
        } else if (walletStore.walletType === 'phantom' && window.solana?.isPhantom) {
          const resp = await window.solana.connect({ onlyIfTrusted: true });
          if (resp?.publicKey) {
            const address = resp.publicKey.toString();
            walletStore.setWallet(address, 'phantom');
            await api.post('/updateAddress', { address: walletStore.walletAddress, type: 'phantom', refererCode: route.params.refCode });
          }
        }
      } catch (error) {
        console.error('Ошибка проверки статуса кошелька:', error);
        walletStore.clearWallet();
      }
    };

    onMounted(() => {
      checkWalletStatus();

      tonConnectUI.onStatusChange((walletInfo) => {
        if (walletInfo) {
          const rawAddress = walletInfo.account.address;
          walletStore.setWallet(rawAddress, 'ton');
          api.post('/updateAddress', { address: walletStore.walletAddress, type: 'ton', refererCode: route.params.refCode }).catch((err) =>
              console.error('Ошибка обновления адреса:', err)
          );
        } else {
          walletStore.clearWallet();
        }
      });

      window.addEventListener('scroll', handleScroll);
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      t,
      walletAddress,
      formattedWalletAddress,
      openModal,
      disconnectWallet,
      scrollToTop,
      smoothScroll,
      openCasino,
      isFixed,
      isProfileMenuVisible,
      toggleProfileMenu,
      navigateToProfile,
    };
  },
};
</script>

<style lang="scss">
.p-menu-item-link {
  color: rgb(195, 195, 195) !important;
}

.p-menu-item:not(.p-disabled) .p-menu-item-content:hover .p-menu-item-link {
  color: black !important;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  gap: 20px;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: all 0.3s ease;

  &.fixed {
    background-color: rgb(52, 55, 64);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.53);
  }

  &-logo {
    width: 120px;
    cursor: pointer;
    @media (max-width: 1280px) {
      width: 50%;
      max-width: 100px;
    }
    @media (max-width: 420px) {
      max-width: 75px;
    }
  }

  &-menu {
    color: rgb(195, 195, 195);
    cursor: pointer;

    &:hover {
      color: white;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  &-button {
    border-radius: 25px !important;
    font-size: 1rem !important;
    color: white;

    @media (max-width: 1280px) {
      font-size: 0.8rem !important;
    }
  }

  &-play {
    @media (max-width: 991px) {
      display: none;
    }
  }

  &-address {
    margin-left: -10px;
    color: white;
    font-weight: 700;
  }
}

.profile-menu-container {
  position: relative;
  display: inline-block;
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #292B33;
  border-radius: 6px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
  margin-top: 5px;
  transform: translate(32%, 5%);
}

.profile-menu-item {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.profile-menu-item:hover {
  background-color: var(--surface-hover, #f8f9fa);
  color: black;
}

.profile-menu-item i {
  margin-right: 0.5rem;
}
</style>

// src/services/phantomService.js
import { useWalletStore } from "@/store/wallet";
import { useModalStore } from "@/store/modal";
import { api } from "@/plugins/api";

export async function connectPhantomWallet(refererCode) {
    const walletStore = useWalletStore();
    const modalStore = useModalStore();
    const adapterName = 'Phantom';
    const isIPhone = /iPhone/i.test(navigator.userAgent); // Проверка на iPhone

    try {
        console.log('Phantom connection attempt:', {
            hasWindowSolana: !!window.solana,
            isPhantom: window.solana?.isPhantom,
            isConnected: window.solana?.isConnected,
            solanaObject: window.solana,
        });

        // Если есть window.solana (для не-iPhone устройств)
        if (window.solana && window.solana.isPhantom) {
            console.log('Detected Phantom provider, connecting...');
            const walletAdapter = window.solana;

            if (!walletAdapter.isConnected) {
                console.log('Wallet not connected, attempting to connect...');
                const response = await walletAdapter.connect();
                console.log('Connect response:', JSON.stringify(response, null, 2));
            } else {
                console.log('Wallet already connected');
            }

            if (!walletAdapter.publicKey) {
                console.error('No publicKey available after connect');
                throw new Error('Failed to get publicKey from Phantom');
            }

            const publicKey = walletAdapter.publicKey.toString();
            console.log('PublicKey retrieved:', publicKey);
            walletStore.setWallet(publicKey, 'solana', adapterName, walletAdapter);

            // Отправляем данные на бэкенд
            await api.post('/updateAddress', {
                address: publicKey,
                type: 'solana',
                refererCode
            });
            console.log('API request completed');
            modalStore.closeModal();

            walletAdapter.on("disconnect", () => {
                console.log(`${adapterName} wallet disconnected`);
                walletStore.clearWallet();
            });

            return walletAdapter;
        }

        // Если нет window.solana и это не iPhone, используем стандартный deep-link
        console.log('No Phantom provider detected, redirecting to deep-link...');
        const url = window.location.href;
        window.location.href = `https://phantom.app/ul/browse/${encodeURIComponent(url)}?ref=${encodeURIComponent(refererCode)}`;
        await new Promise(resolve => setTimeout(resolve, 2000));
        throw new Error('Please continue in Phantom Wallet browser');

    } catch (error) {
        console.error(`Ошибка подключения ${adapterName} кошелька:`, error);
        if (error.name === 'WalletConnectionError') {
            throw new Error(`${adapterName} connection failed: ${error.message}`);
        } else if (error.message?.includes('user rejected')) {
            throw new Error('User rejected the connection');
        }
        throw error;
    }
}
<template>
  <div class="mobile flex justify-center">
    <Drawer v-model:visible="visible" :header="t('header.menu')" :modal="true" :dismissable="true">
      <ul class="menu-list">
        <li @click="handleCasinoClick"><i class="pi pi-play"></i> {{ t("header.casino") }}</li>
        <li @click="handleNavigation('#casino')"><i class="pi pi-info-circle"></i> {{ t("header.about") }}</li>
        <li @click="handleNavigation('#tokenomic')"><i class="pi pi-chart-bar"></i> {{ t("header.tokenomics") }}</li>
        <li @click="handleNavigation('#loadmap')"><i class="pi pi-map"></i> {{ t("header.roadmap") }}</li>
        <li @click="handleNavigation('#faq')"><i class="pi pi-question-circle"></i> {{ t("header.faq") }}</li>
      </ul>
    </Drawer>
    <Button class="p-button-outlined" icon="pi pi-bars" @click="visible = true" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from 'vue-router';

const { t } = useI18n();
const visible = ref(false);
const router = useRouter();
const route = useRoute();

const smoothScroll = (targetId) => {
  const targetElement = document.querySelector(targetId);
  const headerHeight = document.querySelector('.header')?.offsetHeight || 0;
  if (targetElement) {
    const offsetTop = targetElement.offsetTop - headerHeight;
    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    visible.value = false;
  }
};

const openCasino = () => {
  window.open('https://disigames.click', '_blank');
  visible.value = false;
};

const handleNavigation = (targetId) => {
  if (route.path === '/profile') {
    router.push({ path: '/', hash: targetId });
  } else {
    smoothScroll(targetId);
  }
};

const handleCasinoClick = () => {
  if (route.path === '/profile') {
    router.push('/');
  } else {
    openCasino();
  }
};
</script>

<style scoped>
.mobile {
  @media (min-width: 991px) {
    display: none;
  }
}
.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-list li {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.menu-list li:hover {
  background: rgba(255, 255, 255, 0.1);
}
</style>

<style scoped>
.mobile {
  @media (min-width: 991px) {
    display: none;
  }
}
.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-list li {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.menu-list li:hover {
  background: rgba(255, 255, 255, 0.1);
}
</style>

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Buffer } from 'buffer';
import process from 'process';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import primevue from './plugins/vueprime';
import tonConnectUI from './plugins/tonconnect';
import i18n from './plugins/i18n';
import AOS from 'aos'
import 'aos/dist/aos.css'
import Chatra from '@chatra/chatra';

// Создаем приложение Vue
const app = createApp(App)

let chatraConfig = {
   ID: '7JCcpLhBwsWkRAB34',
   buttonStyle: 'none'
}

window.Buffer = Buffer;
window.process = process;

AOS.init()

app.config.globalProperties.$initChatra = () => {
    Chatra('init', chatraConfig);
  };

app.config.globalProperties.$openChatra = () => {
    Chatra('openChat', true);
  };

// Устанавливаем маршрутизатор и хранилище, затем монтируем приложение  s
app.config.globalProperties.$tonConnectUI = tonConnectUI;
app.use(router)
    .use(createPinia())
    .use(primevue)
    .use(i18n)
    .mount('#app');
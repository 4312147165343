<template>
  <div class="auth">
    <header class="p-header">
      <i class="fa-duotone fa-regular fa-xmark-large close-modal" @click="closeModal" />
    </header>
    <Dialog
        v-model:visible="showWalletDialog"
        modal
        header="Внимание"
        :draggable="false"
        :closable="true"
    >
      <p class="dialog-modal-text">
        {{ dialogMessage }}
      </p>
    </Dialog>

    <Button severity="secondary" class="w-100" @click="handleWalletClick('ton')">
      <img src="@/assets/wallet/tonconnect.svg" alt="Ton" class="wallet-icon" />
      Ton Connect
    </Button>
    <Button
        v-for="wallet in wallets"
        :key="wallet.name"
        severity="secondary"
        @click="handleWalletClick(wallet)"
        class="w-100 flex items-center gap-2 mt-3"
    >
      <img :src="wallet.icon" :alt="wallet.name" class="wallet-icon" />
      {{ wallet.name }}
    </Button>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useWalletStore } from '@/store/wallet';
import { useModalStore } from '@/store/modal';
import { useRoute } from 'vue-router';
import { connectTonWallet } from '@/services/tonService';
import { connectPhantomWallet } from '@/services/phantomService';
import { connectSolflareWallet } from '@/services/solflareService';
import { useToast } from 'primevue/usetoast';
import iconPhantom from '@/assets/wallet/phantom.svg';
import iconSolflare from '@/assets/wallet/solflare.svg';

export default {
  setup() {
    const walletStore = useWalletStore();
    const toast = useToast();
    const modalStore = useModalStore();
    const route = useRoute();

    const wallets = [
      {
        name: 'Phantom',
        icon: iconPhantom,
      },
      {
        name: 'Solflare',
        icon: iconSolflare,
      },
    ];

    const handleWalletClick = async (wallet) => {
      const refererCode = route.params.refCode || '';
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      console.log(`Attempting to connect to ${wallet.name || wallet}...`);

      try {
        if (wallet === 'ton') {
          await connectTonWallet(refererCode);
          modalStore.closeModal();
        } else if (wallet.name === 'Phantom') {
          await connectPhantomWallet(refererCode);
          modalStore.closeModal();
        } else if (wallet.name === 'Solflare') {
          await connectSolflareWallet(refererCode);
          if (isMobile) return;
          modalStore.closeModal();
        }
        console.log('Wallet connection successful.');
      } catch (error) {
        const errorMsg = error && typeof error === 'object' && error.message
            ? error.message
            : typeof error === 'string'
                ? error
                : 'An unexpected error occurred';

        console.error(`Failed to connect ${wallet.name || wallet}:`, error);

        if (errorMsg.includes('extension is not installed')) {
          toast.add({
            severity: 'error',
            summary: 'Phantom Required',
            detail: 'Phantom Wallet extension is not installed. Please download it from https://phantom.app/download.',
            life: 5000,
          });
        } else if (errorMsg.includes('App Store')) {
          toast.add({
            severity: 'info',
            summary: 'Opening Phantom',
            detail: 'Opening Phantom Wallet on your iPhone. If not installed, get it from the App Store.',
            life: 5000,
          });
        } else if (errorMsg.includes('Google Play Store')) {
          toast.add({
            severity: 'info',
            summary: 'Opening Phantom',
            detail: 'Opening Phantom Wallet on your Android. If not installed, get it from the Google Play Store.',
            life: 5000,
          });
        } else if (errorMsg.includes('user rejected')) {
          toast.add({
            severity: 'warn',
            summary: 'Connection Rejected',
            detail: 'You rejected the connection request.',
            life: 3000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Connection Failed',
            detail: `Failed to connect ${wallet.name}: ${errorMsg}`,
            life: 3000,
          });
        }
      }
    };

    const closeModal = () => {
      modalStore.closeModal();
    };

    return {
      wallets,
      handleWalletClick,
      closeModal,
    };
  },
};
</script>
<style lang="scss" scoped>
.auth {
  .p-header { display: flex; height: 30px; }
  .close-modal {
    margin-left: auto;
    cursor: pointer;
    &:hover { color: white; }
  }
  .dialog-modal-text {
    color: white;
  }
  .dialog-modal-link {
    color: #6e6ee4;
    text-decoration: underline;
  }
  .p-dialog-header {
    color: white;
  }
  .wallet-icon {
    width: 30px;
    border-radius: 5px;
  }
}
</style>
import { useWalletStore } from "@/store/wallet";
import { useModalStore } from "@/store/modal";
import { api } from "@/plugins/api";
import SolflareWallet from '@solflare-wallet/sdk';
import { Keypair } from '@solana/web3.js';
import bs58 from 'bs58';

export async function connectSolflareWallet(refererCode) {
    const walletStore = useWalletStore();
    const modalStore = useModalStore();
    const adapterName = 'Solflare';

    try {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        console.log('Is mobile device (Android/iOS):', isMobile);

        if (isMobile) {
            console.log('Using Deep Link for mobile authentication...');
            const dappKeyPair = Keypair.generate();
            const dappEncryptionPublicKey = bs58.encode(dappKeyPair.publicKey.toBytes());
            localStorage.setItem('dappSecretKey', bs58.encode(dappKeyPair.secretKey));

            const params = new URLSearchParams({
                app_url: encodeURIComponent('https://disi.fund'),
                dapp_encryption_public_key: dappEncryptionPublicKey,
                cluster: 'mainnet-beta',
            });

            const connectUrl = `https://solflare.com/ul/v1/connect?${params.toString()}`;
            console.log('Generated Deep Link:', connectUrl);
            window.location.href = connectUrl;
            return; // Для мобильных устройств не ждем ответа
        } else {
            console.log('Using Solflare SDK for non-mobile authentication...');
            let walletAdapter;

            if (window.solflare?.isSolflare) {
                console.log('Detected Solflare extension...');
                walletAdapter = window.solflare;
                if (walletAdapter.isConnected) {
                    console.log('Solflare extension already connected, disconnecting first...');
                    await walletAdapter.disconnect();
                }
            } else {
                console.log('Solflare extension not detected, initializing SDK...');
                walletAdapter = new SolflareWallet({
                    network: 'mainnet-beta',
                    redirectUri: window.location.origin,
                });
            }

            if (!walletAdapter || typeof walletAdapter.connect !== 'function') {
                throw new Error('Solflare wallet adapter is not available or improperly initialized');
            }

            console.log('Attempting to connect to Solflare wallet...');
            await walletAdapter.connect();

            if (!walletAdapter.publicKey) {
                throw new Error('Failed to retrieve public key after connecting to Solflare');
            }

            console.log('Connected successfully, publicKey:', walletAdapter.publicKey.toString());

            const publicKey = walletAdapter.publicKey.toString();
            walletStore.setWallet(publicKey, 'solana', adapterName, walletAdapter);

            await api.post('/updateAddress', {
                address: publicKey,
                type: 'solana',
                refererCode,
            });
            modalStore.closeModal();

            walletAdapter.on('disconnect', () => {
                console.log('Solflare wallet disconnected');
                walletStore.clearWallet();
            });

            return walletAdapter;
        }
    } catch (error) {
        // Убедимся, что ошибка всегда является объектом Error
        const finalError = error instanceof Error
            ? error
            : new Error(typeof error === 'string' ? error : 'Unknown error during Solflare connection');

        console.error('Solflare connection error:', {
            error: finalError,
            message: finalError.message,
            stack: finalError.stack || 'No stack available',
        });

        throw finalError; // Всегда выбрасываем объект Error
    }
}
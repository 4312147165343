<template>
  <div class="content">
    <div class="first">
      <div class="container">
        <Offer data-aos="zoom-in" data-aos-duration="1500" />
        <Casino data-aos="zoom-in" data-aos-easing="ease-in" data-aos-duration="500" />
      </div>
    </div>
    <div class="next">
      <div class="container">
        <Tokenomics data-aos-easing="ease-in" />
        <Loadmap data-aos="fade-right" data-aos-easing="ease-in" />
      </div>
    </div>
    <div class="first">
      <div class="container">
        <Faq data-aos="zoom-in" data-aos-duration="1500" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Offer from '@/pages/Offer.vue'
import Casino from '@/pages/Casino.vue'
import Faq from '@/pages/Faq.vue'
import Tokenomics from '@/pages/Tokenomics.vue'
import Loadmap from '@/pages/Loadmap.vue'
import Footer from '@/pages/Footer.vue'
export default {
  components: {
    Offer,
    Casino,
    Tokenomics,
    Loadmap,
    Faq,
    Footer
  }
}
</script>
<style lang="scss">
.content {
  .next {
    background-color: #1d2124;
    padding-bottom: 100px;
  }
}
</style>
<template>
  <div v-for="(modal, index) in modalStore.activeModals" :key="modal" class="modal-backdrop" :style="{ zIndex: 999 + index }" @click="handleBackdropClick($event, index)">
    <div class="modal-content" @click.stop>
      <component :is="getModalComponent(modal)" @close="closeModal" />
    </div>
  </div>
</template>

<script>
import { useModalStore } from "@/store/modal";
import AuthModal from "./Auth/Auth.vue";
import PaymentsModal from "@/components/Modals/Payments/Payments.vue";

export default {
  components: {
    AuthModal,
    PaymentsModal,
  },
  setup() {
    const modalStore = useModalStore();

    const getModalComponent = (modalName) => {
      const modals = {
        Auth: "AuthModal",
        payments: "PaymentsModal",
      };
      return modals[modalName] || null;
    };

    const closeModal = () => {
      modalStore.closeModal(); // Закрываем верхнее модальное окно
    };

    const handleBackdropClick = (event, index) => {
      // Закрываем только верхнее модальное окно при клике на фон
      if (index === modalStore.activeModals.length - 1) {
        closeModal();
      }
    };

    return {
      modalStore,
      getModalComponent,
      closeModal,
      handleBackdropClick,
    };
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
  backdrop-filter: blur(10px); /* Размытие фона */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #343740;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  @media (max-width: 991px) {
    height: 100%;
    width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
}
</style>